<template>
  <div class="project-details">
    <div class="container pt-3" v-if="!loading">
      <hr class="mt-0" />
      <div class="row">
        <div class="col-md-4">
          <h6 class="subtitle">Project</h6>
          <h5>{{ project.name }}</h5>
        </div>
        <div class="col-md-4 text-start text-md-center">
          <h6 class="subtitle">Sector</h6>
          <h5>{{ project.sector }}</h5>
        </div>
        <div class="col-md-4 text-start text-md-end">
          <h6 class="subtitle">Organization</h6>
          <h5>{{ project.organization }}</h5>
        </div>
      </div>
      <hr />
      <div class="image mb-3">
        <img :src="project.cover" alt="image" class="w-100" />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div v-html="project.description"></div>
          <h6 class="mt-3">Benefits</h6>
          <div
            class="d-inline"
            v-for="(benefit, i) in project.benefits"
            :key="`benefit-${i}`"
          >
            <img :src="benefit.icon" :alt="benefit.name" />
            <p>{{ benefit.name }}</p>
          </div>
          <div v-if="project.benefits.length == 0" class="bg-light-blue p-2">
            There is no data to display
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body bg-white">
              <h6>About</h6>
              <ul class="list-unstyled mb-4">
                <li class="mb-2 text-muted">
                  <i class="fa fa-building me-2"></i
                  ><strong>Organization</strong>: {{ project.organization }}
                </li>
                <li class="mb-2 text-muted">
                  <i class="fa fa-flag me-2"></i><strong>Region</strong>:
                  {{ project.region }}
                </li>
                <li class="mb-2 text-muted">
                  <i class="fa fa-clock-o me-2"></i><strong>Status</strong>:
                  {{ project.status }}
                </li>
                <li class="mb-2 text-muted">
                  <i class="fa fa-calendar me-2"></i
                  ><strong>Start Date</strong>:
                  {{ project.start_date }}
                </li>
              </ul>
              <h6>Contacts</h6>
              <ul class="list-unstyled mb-4">
                <li class="mb-2 text-muted">
                  <i class="fa fa-globe me-2"></i><strong>Website</strong>:
                  {{ project.website }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 featured-projects">
        <div class="col-md-12">
          <h5>Similar Projects</h5>
          <hr />
        </div>
        <div
          class="col-md-3"
          v-for="(prj, i) in similar"
          :key="`featured-${i}`"
        >
          <div class="card mb-4">
            <div class="card-body">
              <div class="text-center">
                <router-link
                  :to="{
                    name: 'our-projects.show',
                    params: { id: prj.id },
                  }"
                >
                  <span class="category">{{ prj.sector }}</span>
                  <img
                    :src="prj.logo"
                    alt="featured"
                    class="card-img-top"
                    style="height: 150px; width: auto"
                  />
                </router-link>
              </div>
              <div class="text-muted text-sm">
                <i class="fa fa-map-marker me-2"></i>{{ prj.region }}
              </div>
              <h6 class="my-2">
                <router-link
                  :to="{
                    name: 'our-projects.show',
                    params: { id: prj.id },
                  }"
                  >{{ prj.name }}</router-link
                >
              </h6>
              <p>
                {{ prj.description }}
              </p>
              <router-link
                :to="{
                  name: 'our-projects.show',
                  params: { id: prj.id },
                }"
                class="text-primary"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-3" v-if="loading">
      <loading />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      project: {},
      similar: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/projects/${this.$route.params.id}`)
        .then((response) => {
          this.project = response.data.project;
          this.similar = response.data.similar;
          this.loading = false;
        });
    },
  },
};
</script>
