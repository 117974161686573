<template>
  <div>

    <div class="site-content">
      <router-view />
    </div>

  </div>
</template>

<script>
export default {
  
};
</script>
