<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mt-3 mt-md-5">Our Projects</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae
            laboriosam adipisci voluptate quaerat, doloremque eligendi beatae
            iure. Velit, ut possimus! Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Ipsa quaerat, voluptate atque inventore totam
            dolorum.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
            nihil voluptatum iure suscipit eum hic iste ratione necessitatibus
            quisquam. Aut molestiae ipsam amet delectus quos. Lorem, ipsum dolor
            sit amet consectetur adipisicing elit. Obcaecati, molestias?
          </p>
          <ul>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
          </ul>
        </div>
        <div class="col-md-6 desktop-only">
          <img src="/theme/projects.png" alt="projects" class="w-100 mt-5" />
        </div>
      </div>
      <hr />
    </div>

    <div v-if="!loading">
      <div class="featured-projects pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(project, i) in projects"
                  :key="`featured-${i}`"
                >
                  <div class="card mb-4">
                    <div class="card-body">
                      <div class="text-center">
                        <router-link
                          :to="{
                            name: 'our-projects.show',
                            params: { id: project.id },
                          }"
                        >
                          <span class="category">{{ project.sector }}</span>
                          <img
                            :src="project.logo"
                            alt="featured"
                            class="card-img-top"
                            style="height: 150px; width: auto"
                          />
                        </router-link>
                      </div>
                      <div class="text-muted text-sm">
                        <i class="fa fa-map-marker me-2"></i
                        >{{ project.region }}
                      </div>
                      <h6 class="my-2">
                        <router-link
                          :to="{
                            name: 'our-projects.show',
                            params: { id: project.id },
                          }"
                          >{{ project.name }}</router-link
                        >
                      </h6>
                      <p>
                        {{ project.description }}
                      </p>
                      <router-link
                        :to="{
                          name: 'our-projects.show',
                          params: { id: project.id },
                        }"
                        class="text-primary"
                        >Read More</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12" v-if="projects.length == 0">
                  <alert type="info">There is no data to display.</alert>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body bg-white">
                  <form class="search-projects">
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                      />
                      <button class="btn btn-link">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                  <hr />
                  <h6 class="mb-3">Sectors</h6>
                  <ul class="sidebar-menu">
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i
                        >Design</router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i>Development
                        <span>5</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i
                        >Business</router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i
                        >Project</router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i
                        >Travel</router-link
                      >
                    </li>
                  </ul>
                  <hr />
                  <h6 class="mb-3">Geographic Region</h6>
                  <ul class="sidebar-menu">
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i>West
                        Africa</router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i>East And Central
                        Africa <span>5</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to=""
                        ><i class="fa fa-caret-right me-2"></i>Southern
                        Africa</router-link
                      >
                    </li>
                  </ul>
                  <hr />
                  <h6>Tags</h6>
                  <p>
                    <span
                      class="badge bg-secondary me-2"
                      v-for="i in 10"
                      :key="`tag-${i}`"
                      >Tag {{ i }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      projects: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/projects").then((response) => {
        this.projects = response.data.projects;
        this.loading = false;
      });
    },
  },
};
</script>
