<template>
  <div>
    <public-header />

    <div class="site-content">
      <router-view />
    </div>

    <public-footer />
  </div>
</template>

<script>
import PublicHeader from '@/components/site/PublicHeader.vue'
import PublicFooter from '@/components/site/PublicFooter.vue'

export default {
  components: {
    PublicHeader, PublicFooter
  },

  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: 'dashboard' })
    }
  },
};
</script>
